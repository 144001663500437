<template>

  <div class="head pb grid_width "> 


      <!-- <div v-if="props.brand_package && props.background" class="bg empty_bg" :style="'background-image: url(' + props.background + ')'"></div> -->
  
      <div class="bgbr">

        <UiBread
          :list="props.bread"
          :position="'right'"
          /> 

      </div>

      <div class="phead">

        <div class="ava">
          <img 
            :src="props.avatar" 
            class="avai" 
            :alt="props.nmalt"
            @click="openGallery()"
            > 
            <div v-if="props.itemsPhoto?.length" class="nums">1 / {{ props.itemsPhoto?.length }}</div>
        </div>

   
        <div class="p_name">

          <div class="brand">                
            <NuxtLink v-if="props.link" :to="props.link" class="blogo"> 
              <img :src="props.logo" class="logo" :alt="props.nmalt"> 
            </NuxtLink> 
            <NuxtLink v-if="props.brand_link" class="bname decoration-underline-always" :to="props.brand_link">
              {{ props.brand_name }}
            </NuxtLink>  
          </div>


          <h1 class="name">{{ nm }}</h1> 

        </div>

        <!--  -->


        <div class="space1"></div>
        <div class="space2"></div>


        <!--  -->


        <div class="actions"> 

          <template v-if="props.offical_user">
            <NuxtLink class="user_official" :to="props.offical_user.link"> 
              <div class="avatar">
                <img :src="props.offical_user.avatar_little" :alt="props.offical_user.name">
              </div> 
              <div class="info"> 
                <div class="name">{{ props.offical_user.name }}</div> 
                <div class="status">Official account</div> 
              </div> 
            </NuxtLink>  
          </template>


          <UiButton
            v-if="props.linkShop"
            :target="'_blank'"
            :ico="'icon-cart'"
            :to="props.linkShop"
            :name="$t('strain_view_buy_now_button')"
            type="red"
            tag="NuxtLink"
            />

          <UiButton
            :name="$t('universal_button_follow')"
            :cnt="props.cnt_followers"
            :type="'primary ' + (useAuth().isAuth() ? '' : 'outline')"
            v-if="!props.isfollow"
            @click.native="follow" 
            />

          <UiButton
            :name="$t('universal_button_unfollow')"
            :cnt="props.cnt_followers"
            type=""
            v-if="props.isfollow"
            @click.native="unfollow" 
            />


          </div>  


        <!--  -->

        <div class="stats mob">

          <div class="stat" v-if="props.pos">
            <div class="vl">#{{ props.pos }}</div>
            <div class="tx">Global pos.</div>
          </div>

          <div class="stat">
            <div class="vl"><span class="star_rate"></span><span>{{ props.rate }}</span></div>
            <div class="tx">{{ props.cntHarvests }} harvests</div>
          </div>
          
        </div>

        <div class="stats total">

          <div class="stat rate" v-if="props.pos">
            <div class="vl">#{{ props.pos }}</div>
            <div class="tx">Global pos.</div>
          </div>

          <div class="stat stars">
            <div class="vl"><span class="star_rate"></span><span>{{ props.rate }}</span></div>
            <div class="tx">{{ props.cntHarvests || 0 }} harvests</div>
          </div>

          <div class="stat stat_gp" v-if="props.weightPlant">
            <div class="vl">
              <i class="gp"></i>
              {{ props.weightPlant }}
            </div>
            <div class="tx">g/plant</div>
          </div>

          <div class="stat" v-if="props.cntReports">
            <div class="vl">
              <i class="icon-diary" title="diaries"></i>
              {{ props.cntReports }}
            </div>
            <div class="tx">Diaries</div>
          </div>

          <div class="stat" v-if="props.typeGender">
            <div class="vl">
              <i class="icon-female" title="Fem" v-if="props.typeGender == 'fem'"></i>
              <i class="icon-male" title="Reg" v-else></i>
            </div>
            <div class="tx cap">{{ props.typeGender }}</div>
          </div>

          <div class="stat" v-if="props.typeFlowering">
            <div class="vl">
              <span class="shp auto" title="Autoflower" v-if="props.typeFlowering == 'auto'">A</span>
              <span class="shp photo" title="Photoperiod" v-else>P</span>
            </div>
            <div class="tx cap">{{ props.typeFlowering }}</div>
          </div>


          <div class="stat" v-if="strainHeight">
            <div class="vl">{{ strainHeight }}</div>
            <div class="tx">Height</div>
          </div>

          <div class="stat" v-if="strainThc">
            <div class="vl">{{ strainThc }}%</div>
            <div class="tx">THC</div>
          </div>

          <div class="stat" v-if="strainCbd">
            <div class="vl">{{ strainCbd }}%</div>
            <div class="tx">CBD</div>
          </div>

          <div v-if="props.tastes?.length" class="stat taste">
            <div class="vl">
              <img 
                v-for="(t) in tastesIcon"
                :key="t.id"
                class="ico" 
                :src="'/images/taste/' + t.url_jpg" 
                :title="t.name"
                /> 
            </div>
            <div class="tx">{{ tastesIcon[0]?.name }}</div>
          </div>

          <div v-if="props.effects?.length" class="stat taste">
            <div class="vl">
              <img 
                v-for="(t) in effectsIcon"
                :key="t.id"
                class="ico" 
                :src="'/images/effect/' + t.url_jpg" 
                :title="t.name"
                /> 
            </div>
            <div class="tx">{{ effectsIcon[0]?.name }}</div>
          </div>


        </div>

        <!--  -->

        <div class="desc">
          <div v-if="props.slogan">{{ props.slogan }}</div>
          <div v-else v-html="props.info.substring(0,100)"></div>


          <GeneralShowMore
            v-else
            :desktop-max-height="60"
            :mobile-max-height="80" 
            >
            <div v-if="props.info" v-html="props.info"></div> 
          </GeneralShowMore>

          
        </div>

        <!--  -->

    


      </div>
 
       
  </div>



  <GeneralLightboxVertical 
      v-if="lightboxShow"
      :photo-id="lightboxId" 
      :photos="lightboxPhotos" 
      @close="lightboxShow = false" 
      />

</template>

<script setup>


const { t } = useI18n();
const emits = defineEmits(['follow', 'unfollow'])

const props = defineProps({
  bread: {
    type: Array,
    default: [],
  },
  id: {
    type: Number,
    default: null,
  },
  brand_package: {
    type: Number,
    default: null,
  },
  linkShop: {
    type: String,
    default: null,
  },
  background: {
    type: String,
    default: null,
  },
  cover: {
    type: String,
    default: null,
  },
  cover_mobile: {
    type: String,
    default: null,
  },
  nm: {
    type: String,
    default: null,
  },
  cntHarvests: {
    type: Number,
    default: null,
  },
  info: {
    type: String,
    default: '',
  },
  cntReports: {
    type: Number,
    default: null,
  },
  weightPlant: {
    type: Number,
    default: null,
  },
 
  typeGender: {
    type: String,
    default: null,
  },

  typeFlowering: {
    type: String,
    default: null,
  },

  pos: {
    type: Number,
    default: null,
  },

  cbdMin: {
    type: Number,
    default: null,
  },

  cbdMax: {
    type: Number,
    default: null,
  },

  thcMin: {
    type: Number,
    default: null,
  },

  thcMax: {
    type: Number,
    default: null,
  },
  
  nmalt: {
    type: String,
    default: null,
  },
  slogan: {
    type: String,
    default: null,
  },
  avatar: {
    type: String,
    default: null,
  },
  logo: {
    type: String,
    default: null,
  },
  link: {
    type: String,
    default: null,
  },
  rate: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: null,
  },
  rateof: {
    type: Number,
    required: false,
    default: 10,
  },
  isfollow: {
    type: Boolean,
    default: null,
  },
  cnt_followers: {
    type: Number,
    default: null,
  },
  offical_user: {
    type: Object,
    default: null,
  },
  brand_name: {
    type: String,
    default: null,
  },
  brand_link: {
    type: String,
    default: null,
  },
  effects: {
    type: Array,
    default: null,
  },
  tastes: {
    type: Array,
    default: null,
  },
  itemsPhoto: {
    type: Array,
    default: null,
  },
})


var lightboxShow = ref(false);
var lightboxId = ref(null);


const follow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();
  
  emits('follow')
}

const unfollow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  emits('unfollow')
}




const tastesIcon = computed(() => {

  var limited = props.tastes.slice(0,3);
  limited = JSON.parse(JSON.stringify(limited));

  if(!limited.length){
    return []
  }

  return limited.map((item) => {
    item.name_tr = item.name.replace(/\s+/g, '_').toLowerCase();
    item.name_tr = t('strain_view_tastes_effects_' + item.name_tr);
    return item;
  })

})
 
const effectsIcon = computed(() => {

  var limited = props.effects.slice(0,3);
  limited = JSON.parse(JSON.stringify(limited));

  if(!limited.length){
    return []
  }

  return limited.map((item) => {
    item.name_tr = item.name.replace(/\s+/g, '_').toLowerCase();
    item.name_tr = t('strain_view_tastes_effects_' + item.name_tr);
    return item;
  })

})


const lightboxPhotos = computed(() => {
  
    var items = []; 

    for(var i of props.itemsPhoto){
      let photo = {}; 
      photo.base = i.url_m;
      photo.zoom = i.url_l; 
      items.push(photo);        
    }
    return items;
})

const openGallery = function(key){
  lightboxShow.value = true;
  lightboxId.value = key ?? 0;
}

const strainHeight = computed(() => {
  if(props.heightMin && props.heightMax){
    return Math.round((props.heightMin + props.heightMax) / 2);
  }
  return null;
})

const strainThc = computed(() => {
  if(props.thcMin && props.thcMax){
    return Math.round((props.thcMin + props.thcMax) / 2);
  }
  return null;
})

const strainCbd = computed(() => { 
  if(props.cbdMin && props.cbdMax){
    return Math.round((props.cbdMin + props.cbdMax) / 2);
  }
  return null;
})


</script>




<style scoped>
 
.pghd_gr3{

}
.head{
  padding: 0!important;
}
.head .head_bg{
  /* background-color: var(--un-background-color-gray); */
  
}

.head .head_bg .page_content {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.head .head_bg .head_info {
  display: flex;
  align-items: center;
  width: 100%;
}
.head .head_bg .item_name {
  display: flex;
  align-items: center;
  z-index: 12;
  position: relative;
}
.head .head_bg .item_name .item_logo {
  width: 130px;
  /* background-color: var(--un-background-color-gray); */
  background-color: white;
  padding: 2px;
  border-radius: 200px;
  margin-right: 1rem;
  margin-top: -43px;
  border: 4px var(--un-element-color-gray) solid;
  box-shadow: 0px 0px 20px #00000024;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.head .bg{
  height: 230px;
  margin-bottom: -64px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
} 

.cap {
  text-transform: capitalize;
} 
/*  */

.phead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 1rem 2rem;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-areas: 
    "ava space1 space1"
    "ava name actions"
    "ava stats_total stats_total"
    "ava desc desc"
    "ava space2 space2"
  ;
}

.phead .ava {
  width: 100%;
  background-color: white;
  padding: 2px;
  border-radius: 15px; 
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  grid-area: ava;
  position: relative;
  height: 100%;
}
.phead .ava .nums{
  position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: white;
    padding: 2px 11px;
    border-radius: 60px;
    font-weight: bold;
    box-shadow: 0px 0px 10px #00000038;
}
.phead .ava .avai{
  width: 100%;
  border-radius: 15px;
  aspect-ratio: 191/267;
  object-fit: cover;
}
 

.phead .space1 {
  grid-area: space1;
}

.phead .space2 {
  grid-area: space2;
}

.phead .p_name {
  display: flex;
  flex-direction: column;
  grid-area: name;
}

.phead .p_name .name {
  font-size: 1.4rem;
  font-weight: bold;
    line-height: 1.4rem;
}

.phead .p_name .brand{
  display: flex;
  align-items: center;
  gap: 10px;

}
.phead .p_name .brand .bname{
  font-size: 0.9rem;
}

.phead .p_name .brand .blogo {
  width: 50px;
  padding: 2px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.phead .p_name .brand .blogo .logo {
  
}

.phead .stat.stat_gp .gp {
  width: 20px;
}

.phead .stats.total {
  grid-area: stats_total;
}
.phead .stats.mob {
  grid-area: stats_mob;
  display: none;
}
.phead .stats {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
}
.phead .actions{
  display: flex;
  gap: 0 0.5rem;
  grid-area: actions;
 
}
.phead .stats .stat {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 45px;
}

.phead .stats .stat .vl {
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
}

.phead .stats .stat .icon-female {
  color: #ff0067;
} 
.phead .stats .stat .icon-male {
  color: #0068ff;
}
.phead .stats .stat .shp{
  font-size: 1.4rem; 
  font-family: Courier;
  text-transform: uppercase;
  font-weight: normal;
    line-height: 1.5rem;
}
.phead .stats .stat .shp.auto{
  color: #1eb353;
}
.phead .stats .stat .shp.photo{
  color: #a913d9;
}

.phead .stats .stat .tx {
  color: #898989;
  font-size: 0.8rem;
}

.phead .stats .stat.taste .vl {
  display: flex;
  align-items: center;
}

.phead .stats .stat.taste .vl .ico:nth-child(1) {
  width: 25px;
  height: 25px;
}

.phead .stats .stat.taste .vl .ico:nth-child(2) {
  width: 20px;
  height: 20px;
}

.phead .stats .stat.taste .vl .ico:nth-child(3) {
  width: 15px;
  height: 15px;
}

.phead .desc {
  color: #7c7c7c;
  grid-area: desc;
}
.phead .desc :deep(p){
  margin: 0;
}
.phead .desc a {
  color: var(--un-primary-color);
}


@container pb (max-width: 650px) {

  .head .breadcrumbs{
    display: none;
  }
 

  .phead{
    margin-top: 1rem;
    gap: 0.6rem 1rem;
    grid-template-columns: 48% auto;
    grid-template-areas: 
      "ava space1"
      "ava name"
      "ava stats_mob"
      "ava actions"
      "ava space2"
      "stats_total stats_total"
      "desc desc"
    ;
  }
  .phead .ava{
    width: 100%;
  }
  .phead{

  }
  .phead .actions{
    flex-wrap: wrap;
  }
  .phead .actions :deep(.btn){
    width: 100%;
  }


  .phead .desc {

  }


  .phead .stats.total {

  }
  .phead .stats.mob {
    display: flex;
    gap: 0.5rem;
  }

  .phead .stats .stat.stars {
    display: none;
  }
  .phead .stats .stat.rate {
    display: none;
  }

  .phead .stats.mob .vl{
    font-size: 1.3rem;
  }
  .phead .stats.mob .star_rate{
    width: 25px;
    height: 25px;
  }
}


/* BACKGROUND */

.head .bgbr{
margin-top: 1rem;
}


@media (max-width: 650px) {

  .head .bg{
    height: 180px;

    background-repeat: no-repeat;
    background-size: auto 140%;
    background-position-y: center;
    background-position-x: center;
  } 
}

@container pb (max-width: 600px) {

  .head .bgbr{

  }
  
}



/* brand_cover  */


.brand_cover{

}
.brand_cover .bc{
    width: 100%;
    border-radius: 15px;    
}
.brand_cover .bcm{
  display: none;
}




@container pb (max-width: 650px) {
  .brand_cover{

  }
  .brand_cover .bc{
      display: none; 
  }
  .brand_cover .bcm{ 
    max-width: none;
    width: calc(100% + 4rem);
    display: block;
    margin-left: -2rem;
    margin-top: -2rem;
    margin-bottom: -20px;
  }
}

/*  */





/* user_official */



.user_official {
    position: relative;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    margin-right: 20px;
    height: 30px;
}

.user_official .avatar {
    width: 40px;
    position: relative;
    height: 40px;
}

.user_official .avatar img {
    border-radius: 50%;
    width: 100%;
    border: 2px white solid;
}

.user_official .info {
    width: calc(100% - 50px);
    margin-left: 10px;
    line-height: 1.1rem;
}

.user_official .user_range {
    float: left;
    font-size: 1.6rem;
    margin-right: 15px;
    position: absolute;
    left: 20px;
    bottom: 2px;
}

.user_official .info .name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #494949;
}

.user_official .info .status {
    color: #9c9c9c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


</style>
